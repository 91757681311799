<template>
  <transition name="modal-fade">
    <div class="royalc-modal-backdrop">
      <div class="royalc-modal" :class="classModalSize" role="document" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <header class="royalc-modal-header" id="modalTitle">
          <div class="limit-width">
            <slot name="header">
              This is the default tile!
            </slot>
          </div>
          <button type="button" class="royalc-btn-close float-right" @click="$emit('close')" aria-label="Close modal"><i class="fas fa-times"></i></button>
        </header>
        <section class="royalc-modal-body" id="modalDescription">
          <slot name="body">
            I'm the default body!
          </slot>
        </section>
        <footer class="royalc-modal-footer">
          <slot name="footer">
            I'm the default footer!
            <button type="button" class="btn royalc-btn-default float-right" @click="$emit('close')" aria-label="Close modal">
              Close me!
            </button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  data () {
    return {
      classModalSize: 'royalc-modal-' + (this.modalSize || 'md')
    }
  },
  props: {
    modalSize: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
  /* .modal-fade-enter,
  .modal-fade-leave-active {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease
  } */

  .modal-fade-enter-active {
    animation: bounce-in .5s;
  }
  .modal-fade-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .royalc-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1190;
  }

  .royalc-modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    border-radius: 5px;
    overflow-y: hidden;
    z-index: 1191;
  }
  .royalc-modal-sm {
    width: 35%;
  }
  .royalc-modal-md {
    width: 60%;
  }
  .royalc-modal-lg {
    width: 90%;
  }

  .royalc-modal-header,
  .royalc-modal-footer {
    padding: 15px !important;
  }

  .royalc-modal-header {
    border-bottom: 1px solid #eeeeee;
    color: #84754E;
    justify-content: space-between;
  }

  .royalc-modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .royalc-modal-body {
    position: relative;
    padding: 20px 10px;
    overflow-y: auto;
  }

  .royalc-btn-close {
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: #84754E;
    background: transparent;
  }

  .limit-width {
    max-width: 90%;
    display: inline-block;
  }
</style>
