<template>
  <div class="row justify-content-md-center">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="col-12">
      <filter-side-bar>
        <template slot="ContentSideBar">
          <!-- Filtro por período -->
          <ul class="nav line-tabs borderless flex-center mb-5">
            <li class="nav-item">
              <a class="nav-link" :class="{'active': filters.filPeriodo === 'weekly'}" ref="view_type_weekly" data-toggle="pill" data-period="weekly">{{ $t('period.weekly') }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{'active': filters.filPeriodo === 'monthly'}" ref="view_type_monthly"  data-toggle="pill" data-period="monthly">{{ $t('period.monthly') }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{'active': filters.filPeriodo === 'yearly'}"  ref="view_type_yearly" data-toggle="pill" data-period="yearly">{{ $t('period.yearly') }}</a>
            </li>
          </ul>

          <!-- Filtros -->
          <h5 class="mb-0">Filtrar por:</h5>
          <form id="processes-list-filter" v-on:submit.prevent="applyFilter()">
            <div>
              <div>
                <multiple-select :options="customerList" :id-field="'id_customer'" :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="customerFetchOptions" :value="filters.filCliente" />
              </div>
            </div>
            <!-- Período -->
            <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
            <div class="row">
              <div class="py-1 col-md-6">
                <div class="md-form mb-0">
                  <div class="input-group-icon input-group-icon-right mb-0">
                    <div class="md-form mb-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                      <input data-index="2" :title="this.$i18n.t('form.process.begin_date')"
                             id="begin_date" ref="begin_date" readonly
                             class="form-date md-form-control" type="text"
                             :value="formatDate(filters.filPeriodoDtInicio)">
                      <label :class="{'active': filters.filPeriodoDtInicio}">{{ $t('filter.Start') }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Data Final -->
              <div class="py-1 col-md-6">
                <div class="md-form mb-0">
                  <div class="input-group-icon input-group-icon-right mb-4">
                    <div class="md-form mb-4">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                      <input data-index="2" :title="this.$i18n.t('form.process.end_date')"
                             id="end_date" ref="end_date" readonly class="form-date md-form-control"
                             type="text" :value="formatDate(filters.filPeriodoDtFim)">
                      <label :class="{'active': filters.filPeriodoDtFim}">{{ $t('filter.End') }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Operation / Modal -->
            <h6 class="mb-3 mt-5">{{ $t('operation type and modal') }}</h6>
            <div class="row">
              <!-- Filter Operation  -->
              <div class="col-md-6">
                <div class="md-form mb-0 row">
                  <div class="col-md-9">Importação</div>
                  <div class="col-md-3 text-right no-padding">
                    <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.impo}">
                      <input id="operation_impo" value="2" :disabled="disable.impo" v-on:click="validaFiltro" type="checkbox" ref="operation_impo" :checked="filters.filTipoOperacaoModalImportacao"><span></span></label>
                  </div>
                </div>
                <div class="md-form mb-0 row">
                  <div class="col-md-9">Exportação</div>
                  <div class="col-md-3 text-right no-padding">
                    <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.expo}">
                      <input id="operation_expo" value="1" :disabled="disable.expo" v-on:click="validaFiltro" type="checkbox" ref="operation_expo" :checked="filters.filTipoOperacaoModalExportacao"><span></span>
                    </label>
                  </div>
                </div>
                <div class="md-form mb-0 row">
                  <div class="col-md-9">Nacional</div>
                  <div class="col-md-3 text-right no-padding">
                    <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.nac}">
                      <input id="operation_nac" value="3" :disabled="disable.nac" v-on:click="validaFiltro" type="checkbox"  ref="operation_nac" :checked="filters.filTipoOperacaoModalNacional"><span></span>
                    </label>
                  </div>
                </div>
              </div>
              <!-- Filter Modal  -->
              <div class="col-md-6">
                <div class="md-form mb-0 row">
                  <div class="col-md-9">Marítimo</div>
                  <div class="col-md-3 text-right no-padding">
                    <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.mar}">
                      <input id="modal_mar" value="2" :disabled="disable.mar" v-on:click="validaFiltro" type="checkbox" ref="modal_mar" :checked="filters.filTipoOperacaoModalMaritimo"><span></span></label></div>
                </div>
                <div class="md-form mb-0 row">
                  <div class="col-md-9">Aéreo</div>
                  <div class="col-md-3 text-right no-padding">
                    <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.air}">
                      <input id="modal_air" value="1" :disabled="disable.air" v-on:click="validaFiltro" type="checkbox" ref="modal_air" :checked="filters.filTipoOperacaoModalAereo"><span></span>
                    </label>
                  </div>
                </div>
                <div class="md-form mb-0 row">
                  <div class="col-md-9">Terrestre</div>
                  <div class="col-md-3 text-right no-padding">
                    <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.land}">
                      <input id="modal_land" value="3" :disabled="disable.land" v-on:click="validaFiltro" type="checkbox"  ref="modal_land" :checked="filters.filTipoOperacaoModalTerrestre"><span></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <h6 class="mb-3 mt-5">{{ $t('origin_and_destination') }}</h6>
            <div class="row">
              <div class="col-md-6">
                <div class="py-1">
                  <div class="md-form mb-0">
                    <input  ref="origin"  id="origin" :value="filters.filOrigem" class="md-form-control" type="text">
                    <label :class="{'active': filters.filOrigem}">{{ $t('kpi.value_freight.Origin') }}</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="py-1">
                  <div class="md-form mb-0">
                    <input  ref="destination"  id="destination" :value="filters.filDestino" class="md-form-control" type="text">
                    <label :class="{'active': filters.filDestino}">{{ $t('kpi.value_freight.Destiny') }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-1 text-center">
              <div class="md-form mb-0">
                <div class="row">
                  <div class="col-6">
                    <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                  </div>
                  <div class="col-6">
                    <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </template>
      </filter-side-bar>
    </div>
    <div class="col-12" style="margin-top: -3.8rem;">
        <template>
          <form class="nav-scroll" id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
            <div class="search-mobile" style="display:flex;margin-top: 1rem;">
              <select style="border-radius: 20px 0 0 20px; height: 3rem; border-right: none;border: 1px solid #ced4da; margin-left: 1rem;padding-left: 15px;margin-bottom: 0rem;" v-model="selected">
                <option value='1'>{{ $t('kpi.value_freight.Origin') }}</option>
                <option value='2'>{{ $t('kpi.value_freight.Destiny') }}</option>
                <option value='3'>{{ $t('form.customer.label') }}</option>
              </select>
              <div class="multiple" style="width: 45rem;height: 3rem; border: 1px solid #ced4da; border-left: none; border-radius: 0 20px 20px 0; margin-bottom: 2rem;background-color: #fff;" v-switch="selected">
                <div style="width: 44.3rem;" v-case="'1'">
                    <div style="margin-top: 0.4rem;" class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div style="margin-top: -0.3rem;" class="md-form mb-0 search">
                        <span class="input-icon input-icon-right" style="margin-top: 0.1rem;" v-on:click="sub()">
                          <i class="ti-search"></i>
                        </span>
                        <input  ref="origin"  id="origin" :value="filters.filOrigem" class="md-form-control" type="text">
                       </div>
                      </div>
                    </div>
                  </div>
                  <div style="width: 44.3rem;" v-case="'2'">
                    <div style="margin-top: 0.4rem;" class="md-form mb-0">
                      <div class="input-group-icon input-group-icon-right mb-0">
                        <div style="margin-top: -0.3rem;" class="md-form mb-0">
                          <span class="input-icon input-icon-right" style="margin-top: 0.1rem;" v-on:click="sub()">
                            <i class="ti-search"></i>
                          </span>
                          <input  ref="destination"  id="destination" :value="filters.filDestino" class="md-form-control" type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                <div v-case="'3'">
                  <div style="margin-right: 4px; margin-top:15px;" class="multiple">
                    <multiple-select style="" :options="customerList" :id-field="'id_customer'" :placeholder="this.$i18n.t('')" :fetchOptions="customerFetchOptions" :value="filters.filCliente" />
                  </div>
                </div>
              </div>
            </div>
            <!-- Operation / Modal -->
          <div class="row row-mobile" style="margin-top: -1.2rem !important;align-items: center; align-content: center;justify-content: flex-start !important;flex-wrap: nowrap;display: flex;">
            <div class="coluna35 coluna35-mobile">
              <div style="height: 0%;margin-right: 2px;">
                <abbr title="Marítimo" >
                  <label class="switch" id="Maritimo" v-on:click="validaFiltro">
                    <input  id="modal_mar" value="2" :disabled="disable.mar" v-on:click="sub()" type="checkbox" ref="modal_mar" :checked="filters.filTipoOperacaoModalMaritimo">
                    <span class="slider">
                      <i class="material-icons material-symbols-outlined" style="font-size: 1.9rem;color:#80754E">directions_boat</i>
                    </span>
                  </label>
                </abbr>
              </div>
              <div style="height: 0%;margin-right: 2px;">
                <abbr title="Aéreo">
                  <label class="switch" id="aer" v-on:click="validaFiltro" >
                    <input  value="1" :disabled="disable.air" v-on:click="sub()" type="checkbox" ref="modal_air" :checked="filters.filTipoOperacaoModalAereo">
                    <span class="slider">
                      <i class="material-icons material-symbols-outlined" style="font-size: 1.9rem;color:#80754E">flight</i>
                      </span>
                  </label>
                </abbr>
              </div>
              <div style="height: 0%; margin-right: 2px;">
                <abbr title="Rodoviário">
                    <label class="switch" id="terrestre" v-on:click="validaFiltro" >
                    <input type="checkbox"  value="3" :disabled="disable.land" v-on:click="sub()" ref="modal_land" :checked="filters.filTipoOperacaoModalTerrestre">
                    <span class="slider">
                      <i class="material-icons material-symbols-outlined" style="font-size: 1.9rem;color:#80754E">local_shipping</i>
                    </span>
                  </label>
                </abbr>
              </div>
              <div style="height: 0%; margin-right: 2px;">
                <abbr title="Importação">
                  <label class="switch" id="Importacao" v-on:click="validaFiltro" >
                    <input id="operation_impo" value="2" :disabled="disable.impo" v-on:click="sub()" type="checkbox" ref="operation_impo" :checked="filters.filTipoOperacaoModalImportacao">
                    <span class="slider">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 6.48 17.53 2 12.01 2C6.48 2 2 6.48 2 12C2 17.5167 6.4746 21.9946 12 22L12 19.96C11.17 18.76 10.52 17.43 10.09 16H12V14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12H16.5C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12H22ZM8.03 8H5.08C6.04 6.35 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM10.09 8C10.52 6.57 11.17 5.24 12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.34 18.92 8ZM8.03 16C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.65 5.08 16H8.03ZM7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12Z" fill="#80754E"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 14C13 13.4477 13.4477 13 14 13L21 13C21.5523 13 22 13.4477 22 14L22 21C22 21.5523 21.5523 22 21 22L14 22C13.4477 22 13 21.5523 13 21L13 14ZM15.5 15.0007C15.2239 15.0007 15 15.2246 15 15.5007L15 19.4948L15 19.5007C15 19.765 15.205 19.9814 15.4647 19.9995C15.4764 20.0003 15.4881 20.0007 15.5 20.0007L15.5016 20.0007L19.5 20.0007C19.7761 20.0007 20 19.7769 20 19.5007C20 19.2246 19.7761 19.0007 19.5 19.0007L16.7071 19.0007L19.8497 15.8582L19.1425 15.1511L16 18.2937L16 15.5007C16 15.2246 15.7761 15.0007 15.5 15.0007Z" fill="#80754E"/>
                      </svg>
                    </span>
                  </label>
                </abbr>
              </div>
              <div style="height: 0%; margin-right: 2px;">
                <abbr title="Exportação">
                  <label class="switch" id="Exportacao" v-on:click="validaFiltro" >
                    <input id="operation_expo" value="1" :disabled="disable.expo" v-on:click="sub()" type="checkbox" ref="operation_expo" :checked="filters.filTipoOperacaoModalExportacao">
                      <span class="slider">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.5167 17.5254 21.9946 12 22L12 19.96C12.83 18.76 13.48 17.43 13.91 16H12V14H14.34C14.43 13.34 14.5 12.68 14.5 12C14.5 11.32 14.43 10.65 14.34 10H9.66C9.57 10.65 9.5 11.32 9.5 12H7.5C7.5 11.32 7.56 10.66 7.64 10H4.26C4.1 10.64 4 11.31 4 12H2ZM15.97 8H18.92C17.96 6.35 16.43 5.07 14.59 4.44C15.19 5.55 15.65 6.75 15.97 8ZM13.91 8C13.48 6.57 12.83 5.24 12 4.04C11.17 5.24 10.52 6.57 10.09 8H13.91ZM5.08 8H8.03C8.35 6.75 8.81 5.55 9.41 4.44C7.57 5.07 6.04 6.34 5.08 8ZM15.97 16C15.65 17.25 15.19 18.45 14.59 19.56C16.43 18.93 17.96 17.65 18.92 16H15.97ZM16.5 12C16.5 12.68 16.44 13.34 16.36 14H19.74C19.9 13.36 20 12.69 20 12C20 11.31 19.9 10.64 19.74 10H16.36C16.44 10.66 16.5 11.32 16.5 12Z" fill="#80754E"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 13C2.44772 13 2 13.4477 2 14V21C2 21.5523 2.44772 22 3 22H10C10.5523 22 11 21.5523 11 21V14C11 13.4477 10.5523 13 10 13H3ZM4.5 15C4.22386 15 4 15.2239 4 15.5C4 15.7761 4.22386 16 4.5 16H7.29284L4.14642 19.1464L4.85353 19.8535L8 16.7071V19.5C8 19.7761 8.22386 20 8.5 20C8.77614 20 9 19.7761 9 19.5V15.5C9 15.4655 8.9965 15.4318 8.98984 15.3992C8.94322 15.1714 8.74162 15 8.5 15M4.5 15H8.49869H4.5Z" fill="#80754E"/>
                        </svg>
                    </span>
                  </label>
                </abbr>
              </div>
              <div style="height: 0%; margin-right: 2px;">
                <abbr title="Nacional">
                  <label class="switch" id="Nacional" v-on:click="validaFiltro" >
                    <input v-on:click="sub()" id="operation_nac" value="3" :disabled="disable.nac" type="checkbox"  ref="operation_nac" :checked="filters.filTipoOperacaoModalNacional">
                    <span class="slider">
                      <img src="../../../assets/css/icons/rc-icons/brasil_estados.svg.svg" style="fill:#80754E; width: 2rem; margin-left: 0.2rem;">
                    </span>
                  </label>
                </abbr>
              </div>
            </div>
            <div class="col20" style="display:flex;min-width: 33%;max-width: 56%;margin-left: -2rem;margin-bottom: 0;margin-left: 1rem;">
              <div>
                <h6 class="mb-0 mt-4" style="margin-bottom: -0.5rem !important;color: #5e5e5e94; margin-top: 0 !important;margin-left: 1rem;">{{ $t('kpi.volume.period') }}</h6>
                <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form mb-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.begin_date')"
                              style="border-bottom: 1px solid #ced4da !important;"
                              id="begin_date" ref="begin_date" readonly
                              class="form-date md-form-control" type="text"
                              :value="$util.formatDate(filters.filPeriodoDtInicio)">
                        <label :class="{'active': filters.filPeriodoDtInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Data Final -->
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.end_date')"
                              style="border-bottom: 1px solid #ced4da !important;"
                              id="end_date" ref="end_date" readonly class="form-date md-form-control"
                              type="text" :value="$util.formatDate(filters.filPeriodoDtFim)">
                        <label :class="{'active': filters.filPeriodoDtFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <h6 class="mb-3 mt-5"></h6>
            </div>
            <div  onclick="getElementByClassName('teste').style.display='none'" style="padding-bottom: 1rem;padding-right: 12rem; width: 1%; position: absolute;right: 18rem;">
              <div class="md-form mb-0" style="margin-top: 0rem;">
                <div class="row">
                  <div class="col-3" style="max-width: 75%;">
                    <button type="submit" id="button" class="btn btn-primary btn-fill btn-block mr-2 button">Aplicar Filtros
                    </button>
                  </div>
                  <!-- <div class="col-6">
                    <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                      $t('filter.reset') }}
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </form>
        </template>
    </div>
    <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3 filtros" style="display: flex; ">
          <div class="itemContent mx-1 mb-2" style="width: 8.5rem;">
            <button type="button" v-on:click="resetFilter" id="btn" class="itemContent mx-1 mb-2 btn btn-fill btn-block mr-2">Limpar Filtros
              <i class="material-icons material-symbols-outlined" style="font-size: .9rem;color:#80754E">
                filter_list
              </i>
            </button>
          </div>
          <filter-container :activeFilterList="activeFilterList" />
      </div>
      <div class="col-12 titulo">
        <h2>Valores de Frete</h2>
        <h5>KPI > Valores de Frete</h5>
      </div>
      <div class="col-md-12" style="padding: 0 !important">
        <div v-if="dataTable" class="card card-fullheight" style="max-height: 550px;">
          <div class="card-body">
            <h5 class="box-title-media mb-5">{{ $t('kpi.value_freight.Average_per_origin_and_destination') }}</h5>
            <perfect-scrollbar class="scroll-area">
              <div class="" >
                <table class="table table-hover align-middle table-clickable">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{{ $t('modal.title') }}</th>
                      <th scope="col">{{ $t('operation.title') }}</th>
                      <th scope="col">{{ $t('kpi.value_freight.Origin') }}</th>
                      <th scope="col"></th>
                      <th scope="col">{{ $t('kpi.value_freight.Destiny') }}</th>
                      <th scope="col">{{ $t('kpi.value_freight.equipment') }}</th>
                      <th scope="col">{{ $t('kpi.value_freight.Average_price') }}</th>
                      <th scope="col">{{ $t('kpi.value_freight.Transit_time') }}</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody v-if="dataTable">
                    <tr v-for="(item, index) in dataTable" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ $t('modal.options.'+item.modal) }}</td>
                      <td>{{ $t('operation.options.'+item.operation)}}</td>
                      <td v-if="item.origin">{{ item.origin }}</td>
                      <td v-else> --- </td>
                      <td><i class=" pr-5 ft-arrow-right"></i></td>
                      <td v-if="item.destination">{{ item.destination }}</td>
                      <td v-else> --- </td>
                      <td>{{ item.equipment }}</td>
                      <td>{{ avgPrice(item.avg_freigth_price * item.fator)   }}</td>
                      <td>{{ item.transit_time }}</td>
                      <td>
                        <button class="btn btn-link"
                                @click="showRowDetails(item, index)">
                          <i class="text-primary  rc-Ativo-210 font-20"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
        <div v-else class="card card-fullheight">
          <div class="card-body">{{ $t('Loading') }}</div>
        </div>
      </div>
      <modal v-if="showModal" @close="closeRowDetails" modal-size="md">
        <template slot="ContentSideBar">
          {{ $t('kpi.volume.title') }}
        </template>
        <template slot="header">
          <h5 class="box-title mt-1 mb-0">Evolução do Preço do Frete por Período</h5>
        </template>
        <template slot="body">
          <div class="container">
            <div class="row details ">
              <div class="col-6">
                <label>Modal:</label>{{ $t('modal.options.'+selectedRow.modal) }}
              </div>
              <div class="col-6">
                <label>Operação:</label> {{ $t('operation.options.'+selectedRow.operation)}}
              </div>
              <div class="col-6">
                <label>Origem:</label> {{ selectedRow.origin }}
              </div>
              <div class="col-6">
                <label>Destino:</label> {{ selectedRow.destination }}
              </div>
              <div class="col-12">
                <label>Equipamento:</label> {{ selectedRow.equipment }}
              </div>
            </div>
            <div class="row">
            <div class="chart-container chart-container-frete-periodo-linha">
              <canvas id="FretePeriodoLinha" style="height:300px;"></canvas>
              <div class="no-data FretePeriodoLinha" style="display: none"></div>
              <loading :active.sync="isModalLoading"
                       :can-cancel="false"
                       :is-full-page="false"></loading>
            </div>
            </div>
          </div>
        </template>
        <template slot="footer">
          <div style="display: none"></div>
        </template>
      </modal>
    </div>
</template>

<script>
// eslint-disable-next-line
import Vue from 'vue'
import { util } from '@/utils/utils.js'
import VSwitch from 'v-switch-case'
import dayjs from 'dayjs'
import KpiService from '@/services/KPI/KpiService'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainerProcess'
import Modal from '@/components/Modal'
import Chart from 'chart.js'
import 'chart.js/dist/Chart.min.css'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// eslint-disable-next-line
import '@/assets/js/custom/data.chartJS.data.render.js'

// Services
import CustomerService from '@/services/CustomerService'

// Components

// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelectProcess.vue'
Vue.use(VSwitch)
// Por padrão label vem habilitada para todos os gráficos (Removendo para poder colocar apenas nos que desejar)
Chart.plugins.unregister(ChartDataLabels)

export default {
  name: 'FinantialValorFrete',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.title') + ' ' + this.$i18n.t('kpi.value_freight.title') + ' - %s'
    }
  },
  data () {
    return {
      selected: '1',
      customerList: [],
      selectedRow: {},
      isLoading: false,
      isModalLoading: false,
      fullPage: true,
      activeFilterList: {
        customer: [],
        period: 'monthly',
        origin: null,
        destination: null,
        beginDate: null,
        endDate: null
      },
      filter: {},
      chartLineArea: null,
      dataTable: null,
      // Charts
      fretePeriodoLinhaChart: null,
      fretePeriodoLinhaChartData: null,
      kpiDados: null,
      showModal: false,
      disable: {
        expo: false,
        impo: false,
        nac: false,
        mar: false,
        air: false,
        land: false
      }
    }
  },
  components: {
    FilterSideBar,
    Loading,
    FilterContainer,
    PerfectScrollbar,
    Modal,
    MultipleSelect
  },
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    if (this.user) {
      this.getKpiData()
    }
    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    sub () {
      document.getElementById('button').click()
    },
    closeRowDetails () {
      this.selectedRow = {}
      this.showModal = false
    },
    showRowDetails (row, index) {
      this.selectedRow = row
      this.showModal = true
      this.isModalLoading = true

      let params = this.filter
      params.operation = [row.operation]
      params.modal = [row.modal]
      params.origin = row.origin
      params.destination = row.destination
      params.tipo_equipamento = (row.equipment_type !== null) ? [{ code: row.equipment_type }] : null

      if (this.user) {
        this.getShippingWweightKpiData(params)
      }
    },
    buildFilters () {
      this.filter.period = this.filters.filPeriodo

      // Operation Filter
      this.filter.operation = []
      if (this.filters.filTipoOperacaoModalExportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalExportacao)
      }
      if (this.filters.filTipoOperacaoModalImportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalImportacao)
      }
      if (this.filters.filTipoOperacaoModalNacional) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalNacional)
      }

      // Modal Filter
      this.filter.modal = []
      if (this.filters.filTipoOperacaoModalTerrestre) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalTerrestre)
      }
      if (this.filters.filTipoOperacaoModalMaritimo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalMaritimo)
      }
      if (this.filters.filTipoOperacaoModalAereo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalAereo)
      }

      // Filter Processes
      this.filter.processes = []
      if (this.filters.situacaoAbertos) {
        this.filter.processes.push(this.filters.situacaoAbertos)
      }
      if (this.filters.situacaoEmbarcados) {
        this.filter.processes.push(this.filters.situacaoEmbarcados)
      }

      // Period Date Filter
      this.filter.beginDate = this.filters.filPeriodoDtInicio
      this.filter.endDate = this.filters.filPeriodoDtFim

      // Origins and destination
      this.filter.origin = this.filters.filOrigem
      this.filter.destination = this.filters.filDestino

      // Customer
      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
    },
    // Filter
    applyFilter () {
      this.customerList = []
      let dayParse = 'DD/MM/YYYY'
      // Period

      if (this.$refs.view_type_weekly.classList.contains('active')) {
        this.filters.filPeriodo = 'weekly'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      // Operation Filter
      this.filters.filTipoOperacaoModalExportacao = this.$refs.operation_expo.checked ? parseInt(this.$refs.operation_expo.value) : null
      this.filters.filTipoOperacaoModalImportacao = this.$refs.operation_impo.checked ? parseInt(this.$refs.operation_impo.value) : null
      this.filters.filTipoOperacaoModalNacional = this.$refs.operation_nac.checked ? parseInt(this.$refs.operation_nac.value) : null

      // Modal Filter
      this.filters.filTipoOperacaoModalTerrestre = this.$refs.modal_land.checked ? parseInt(this.$refs.modal_land.value) : null
      this.filters.filTipoOperacaoModalMaritimo = this.$refs.modal_mar.checked ? parseInt(this.$refs.modal_mar.value) : null
      this.filters.filTipoOperacaoModalAereo = this.$refs.modal_air.checked ? parseInt(this.$refs.modal_air.value) : null

      // Origin and Destination
      this.filters.filOrigem = this.$refs.origin.value ? this.$refs.origin.value : null
      this.filters.filDestino = this.$refs.destination.value ? this.$refs.destination.value : null

      // Date period
      this.filters.filPeriodoDtInicio = dayjs(this.$refs.begin_date.value, dayParse).isValid() ? dayjs(this.$refs.begin_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoDtFim = dayjs(this.$refs.end_date.value, dayParse).isValid() ? dayjs(this.$refs.end_date.value, dayParse).toISOString() : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()

      /* Todo: Reload Charts */
      this.getKpiData()
    },
    resetFilter () {
      this.hideQuichSidebar()
      // hasFilter: false
      this.filters.filProcesso = null
      this.filters.filReferenciaCliente = null
      this.filters.filHBL = null
      this.filters.filPeriodoEmbarqueDtInicio = null
      this.filters.filPeriodoEmbarqueDtFim = null
      this.filters.filPeriodoDesembarqueDtInicio = null
      this.filters.filPeriodoDesembarqueDtFim = null
      //  weekly monthly yearly
      this.filters.filPeriodo = 'monthly'
      //  Operação
      this.filters.filTipoOperacaoModalExportacao = 1
      this.filters.filTipoOperacaoModalImportacao = 2
      this.filters.filTipoOperacaoModalNacional = 3
      //  Modal
      this.filters.filTipoOperacaoModalAereo = 1
      this.filters.filTipoOperacaoModalMaritimo = 2
      this.filters.filTipoOperacaoModalTerrestre = 3
      //  Situação
      this.filters.situacaoAbertos = 1
      this.filters.situacaoEmbarcados = 2
      // Period Date
      this.filters.filPeriodoDtInicio = (dayjs().add(-1, 'year').date(1).hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss')
      this.filters.filPeriodoDtFim = dayjs().date(dayjs().daysInMonth()).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss')
      // Origin  and destinations
      this.filters.filOrigem = null
      this.filters.filDestino = null

      // Customer
      this.filters.filCliente = []

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      /* Todo: Reload Charts */
      this.getKpiData()
    },
    validaFiltro () {
      let contOp = 0
      let contMo = 0

      contOp = this.$refs.operation_expo.checked ? (contOp + 1) : contOp
      contOp = this.$refs.operation_impo.checked ? (contOp + 1) : contOp
      contOp = this.$refs.operation_nac.checked ? (contOp + 1) : contOp

      contMo = this.$refs.modal_mar.checked ? (contMo + 1) : contMo
      contMo = this.$refs.modal_air.checked ? (contMo + 1) : contMo
      contMo = this.$refs.modal_land.checked ? (contMo + 1) : contMo

      this.disable.expo = false
      this.disable.impo = false
      this.disable.nac = false
      this.disable.mar = false
      this.disable.air = false
      this.disable.land = false

      if (contOp === 1) {
        switch (true) {
          case this.$refs.operation_expo.checked:
            this.disable.expo = true
            break
          case this.$refs.operation_impo.checked:
            this.disable.impo = true
            break
          case this.$refs.operation_nac.checked:
            this.disable.nac = true
            break
        }
      }

      if (contMo === 1) {
        switch (true) {
          case this.$refs.modal_mar.checked:
            this.disable.mar = true
            break
          case this.$refs.modal_air.checked:
            this.disable.air = true
            break
          case this.$refs.modal_land.checked:
            this.disable.land = true
            break
        }
      }
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatEmpty (value) {
      if (!value) {
        return ' - '
      }

      return value
    },
    formatDate (value, locale) {
      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    showDecimal (number, decimals, decPoint, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      decimals = decimals || 2
      decPoint = decPoint || ','
      thousandsSep = thousandsSep || '.'

      if (number) {
        return util.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    // End Filter
    // Charts
    addChartBarData (chart, data) {
      this.legend = []
      chart.data.labels = []
      chart.data.datasets = []

      chart.update()

      chart.data.labels = data.labels

      for (var propertyName in data) {
        let item = data[propertyName]

        let chartData = []
        let chartLabels = []

        let hideLabels = item.hideLabels || false

        for (let value in item.values) {
          let itemValue = item.values[value]

          chartData.push(itemValue.value)
          chartLabels.push(itemValue.label)
        }

        let dataSet = {
          attribute: item.year,
          hideLabels: hideLabels,
          backgroundColor: item.color,
          stack: item.year,
          data: chartData
        }

        if (item.type === 'area') {
          dataSet.type = 'line'
          dataSet.fill = false
          dataSet.borderWidth = 2
          // dataSet.lineTension = 0.1
          dataSet.borderColor = Chart.helpers.color(`${item.color}`).rgbString()
          dataSet.backgroundColor = Chart.helpers.color(`${item.color}`).alpha(0.5).rgbString()
          dataSet.fill = true
          chart.options.spanGaps = true
        }

        if (item.type === 'line') {
          dataSet.type = 'line'
          dataSet.fill = false
          dataSet.borderWidth = 2
          dataSet.lineTension = 0.1
          dataSet.borderColor = Chart.helpers.color(`${item.color}`).rgbString()
          dataSet.backgroundColor = Chart.helpers.color(`${item.color}`).alpha(0.5).rgbString()
          chart.options.spanGaps = true
        }

        chart.data.datasets.push(dataSet)
        chart.data.labels = chartLabels
        chart.update()
      }
    },
    newBarChart (selector, labelFormatter, hideGridLine, hideLabelsTitle) {
      labelFormatter = labelFormatter || false
      hideGridLine = hideGridLine || false
      hideLabelsTitle = hideLabelsTitle || false

      let MONTHS_SH = [
        (this.$i18n.t('date.month.Jan')).substring(0, 3),
        (this.$i18n.t('date.month.Feb')).substring(0, 3),
        (this.$i18n.t('date.month.Mar')).substring(0, 3),
        (this.$i18n.t('date.month.Apr')).substring(0, 3),
        (this.$i18n.t('date.month.May')).substring(0, 3),
        (this.$i18n.t('date.month.Jun')).substring(0, 3),
        (this.$i18n.t('date.month.Jul')).substring(0, 3),
        (this.$i18n.t('date.month.Aug')).substring(0, 3),
        (this.$i18n.t('date.month.Sep')).substring(0, 3),
        (this.$i18n.t('date.month.Oct')).substring(0, 3),
        (this.$i18n.t('date.month.Nov')).substring(0, 3),
        (this.$i18n.t('date.month.Dec')).substring(0, 3)
      ]

      var config = {
        type: 'bar',
        data: {
          labels: MONTHS_SH

        },
        options: {
          bezierCurve: false,
          legend: {
            display: false
          },
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                if (hideLabelsTitle) {
                  return ''
                }

                let label = data.datasets[tooltipItem[0].datasetIndex].label

                if (label) {
                  return data['labels'][tooltipItem[0]['index']] + ' (' + label + ')'
                } else {
                  return data['labels'][tooltipItem[0]['index']]
                }
              },
              label: function (tooltipItem, data) {
                let currentColumn = ''
                let currentStack = ''
                let dataset = data.datasets[tooltipItem.datasetIndex]

                if (!dataset.hideLabels) {
                  currentColumn = data.datasets[tooltipItem.datasetIndex].attribute + ': '
                  currentStack = data.datasets[tooltipItem.datasetIndex].stack + ': '
                }
                let valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                // Loop through all datasets to get the actual total of the index
                var total = 0
                for (var i = 0; i < data.datasets.length; i++) {
                  let dataSet = data.datasets[i]

                  if (dataSet.stack === currentStack) {
                    total += data.datasets[i].data[tooltipItem.index]
                  }
                }

                // If it is not the last dataset, you display it as you usually do
                if (data.datasets[tooltipItem.datasetIndex].total) {
                  if (total > 0) {
                    if (labelFormatter) {
                      return [currentColumn + labelFormatter(total)]
                    }

                    return [currentColumn + (total)]
                  } else {
                    return false
                  }
                } else { // .. else, you display the dataset and the total, using an array
                  if (valor > 0) {
                    if ({}.toString.call(labelFormatter) === '[object Function]') {
                      return currentColumn + labelFormatter(valor)
                    }

                    return currentColumn + (valor)
                  } else {
                    return false
                  }
                }
              },
              afterLabel: function (tooltipItem, data) {
                /* var dataset = data['datasets'][0];
                  var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                  return '(' + percent + '%)'; */
              }
            },
            backgroundColor: '#FFF',
            titleFontSize: 14,
            titleSpacing: 10,
            titleMarginBottom: 10,
            bodySpacing: 4,
            titleFontColor: '#3f3f3f',
            bodyFontColor: '#333',
            bodyFontSize: 13,
            cornerRadius: 3,
            yPadding: 10,
            xPadding: 10,
            displayColors: true
          },
          elements: {
            rectangle: {
              borderWidth: 2
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          axes: {
            display: false
          },
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.95,
              barPercentage: 1.0,
              gridLines: {
                color: 'rgba(0, 0, 0, 0)'
              },
              scaleLabel: {
                display: true,
                labelString: this.$i18n.t('period.title')
              }
            }],
            yAxes: [{
              stacked: false,
              categoryPercentage: 1.0,
              barPercentage: 1.0,
              ticks: {
                beginAtZero: true
              },
              scaleLabel: {
                display: true,
                labelString: this.$i18n.t('quantity')
              }
            }]
          }
        }
      }

      if (hideGridLine) {
        config.options.scales.yAxes = [{
          stacked: false,
          categoryPercentage: 1.0,
          barPercentage: 0.5,
          ticks: {
            display: false
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0)'
          }
        }]
      }

      var ctxProcesses = document.getElementById(selector).getContext('2d')
      return new Chart(ctxProcesses, config)
    },
    // End Charts
    avgPrice (value) {
      return util.formatToPrice(value, 'BRL')
    },
    returnItemObject (item) {
      let itemTable = {}
      if (item) {
        itemTable = Object.entries(item)
      }
      return itemTable
    },
    getRandomColor () {
      var r = Math.floor(Math.random() * 255)
      var g = Math.floor(Math.random() * 255)
      var b = Math.floor(Math.random() * 255)
      return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    },
    getKpiData () {
      let me = this
      this.isLoading = true

      if (this.$store.getters.userInfo) {
        KpiService.getValueFreight(this.$store.getters.userInfo.customer_id, this.filter.period, this.filter).then(res => {
          me.dataTable = res.data.avg_origin_destination
          this.isLoading = false
        })
      }
    },
    getShippingWweightKpiData (params) {
      let me = this
      me.isLoading = true

      if (this.$store.getters.userInfo) {
        KpiService.getShippingWweight(this.$store.getters.userInfo.customer_id, params.period, params).then(res => {
          me.kpiDados = res.data
          this.buildCharts()

          me.addChartBarData(me.fretePeriodoLinhaChart, res.data.data)
          me.isLoading = false
          me.isModalLoading = false
        })
      }
    },
    buildCharts () {
      this.fretePeriodoLinhaChart = this.newBarChart('FretePeriodoLinha', this.avgPrice)
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return this.$store.getters.aplliedFilters || null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      this.getKpiData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .box-title-media{
      /* Tag/14pt */
      font-family: 'Pluto Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* or 150% */
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      padding-top: 1rem;
      /* Font/Light */
      color: #666666;
  }
    .titulo h2{
      /* Light/24pt */
      font-family: 'Pluto Sans';
      font-style: normal;
      font-weight: 370;
      font-size: 24px;
      line-height: 30px;
      /* identical to box height */
      letter-spacing: -0.02em;
      /* Font/Gold */
      color: #5A4D34;
    }
    .titulo h5{
      /* Regular/12pt */
      font-family: 'Pluto Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height, or 150% */
      display: flex;
      align-items: flex-end;
      /* Font/Dark */
      color: #3D3D3D;
    }
    .titulo{
      margin-left: 0%;
    }
    th, td {
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
    .chart-container {
      position: relative;
      margin: auto;
      width: 100%;
      padding: 0 60px;
    }
    .chart-container-valor-frete {
      height: 330px;
      height: 55vh;
      margin-bottom: 15px;
    }
    .scroll-area {
      position: relative;
      margin: auto;
      width: 100%;
      height: 400px;
    }
    .chart-container {
      position: relative;
      margin: auto;
      width: 100%;
      padding: 0 60px;
    }
    .chart-container-valor-frete {
      height: 330px;
      height: 55vh;
      margin-bottom: 15px;
    }
    .details {
      margin-bottom: 15px;
      font-size: 0.80rem;
    }
    .details label {
      font-weight: bold;
      margin-right: 4px;
      font-size: 0.80rem;
    }
    .disableLabel span{
      background-color: rgba(132,117,78,0.6) !important;
      border-color: rgba(132,117,78,0.1) !important;
    }
    @media only screen and (max-width: 1600px) {
    }
    @media only screen and (min-width: 600px) {
      /* .filtros{
        padding-left: 55px;
      } */
      .table-clickable tbody tr:hover td{
        cursor: default;
      }
      ._horizontal-scroll{
        width: 100%;
        overflow-x: hidden !important;
      }
      .h6-mobile{
        display: none !important
      }
    }
   @media only screen and (max-width: 600px) {
      .calendar-mobile{
        margin-top: -1.5rem
      }
      .row-mobile{
        margin-top: 0;
        align-items: center;
        place-content: center space-evenly;
        flex-wrap: nowrap;
        display: flex;
        flex-direction: column;
      }
      .h6{
        display: none !important
      }
      .row-search{
        display: flex;
        flex-direction: column !important;
      }
      .horizontal-scroll{
        width: 100%;
        overflow-x: scroll !important;
        overflow-y: hidden;
      }
      .input-mobile{
        height: 4.7rem !important;
      }
      .nav-scroll-template{
        max-width: 100% !important;
      }
      .no-bottom-margin{
        width: 320%;
      }
      ._horizontal-scroll{
        width: 100%;
        overflow-x: scroll !important;
        overflow-y: hidden;
      }
      .search-mobile{
        width: 90%;
        margin-bottom: 1rem;
      }
      .search-mobile select{
        width: 88%;
      }
      #linha-vertical{
        display: none !important;
      }
      .coluna35{
        margin-left: 0 !important;
        flex-direction: row;
        flex-wrap: nowrap;
        max-width: 100% !important;
        width: 100% !important;
      }
      .col20{
        width: 100% !important;
        margin: 0 !important;
      };
    }
    input{
      border: none !important;
    }
    .disableLabel span{
      background-color: rgba(132,117,78,0.6) !important;
      border-color: rgba(132,117,78,0.1) !important;
    }
    .switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    }
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .vuetable-slot{
      cursor: default !important;
    }
    .slider {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      position: absolute;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(185, 185, 185, 0.473);
      border-radius: 12px;
      -webkit-transition: .7s;
      transition: .7s;
      border: 1px solid #e4e4e459;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: rgba(255, 255, 255, 0);
      -webkit-transition: .4s;
      transition: .4s;
    }
    .search .md-form-control {
       background-color: rgb(255, 255, 255) !important;
       border: none !important;
    }
    input:checked + .slider {
      background-color: #ffffff00;
      border: 1px solid rgb(128, 117, 78);
      border-radius: 12px;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
    .coluna35{
      max-width: 34%;
      min-width: 18%;
      padding-left: 13px;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    #linha-vertical {
        height: 2rem;
        border-right: 1px solid #C4C4C4;
        margin: 0 1rem 0.5rem -10rem;
    }
    .button{
      width: 10rem;
      height: 3rem;
      color: #555555;
      background-color: #c4c4c400;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    #btn:hover { background-color: #E0E0E0 }
    #btn{
      /* Limpar Filtros */
      height: 32px;
      font-family: 'Pluto Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      /* identical to box height, or 12px */
      background-color: none;
      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
      border: 1px solid #E0E0E0;
      color: #666666;
      display: inline-block;
      padding: 1px 5px;
      display: flex;
      border-radius: 15px;
      align-items: center;
      justify-content: space-between;
    }
    .vuetable-th-slot-customer_name{
      width: 20%;
    }
    .vuetable-td-id_status{
      width: 14%;
    }
    .customername{
      font-family: 'Pluto Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #666666;
    }
    .tracking{
      font-family: 'Pluto Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #666666;
    }
    .itemContemt{
      padding: 1px 10px;
    }
    .nav-link{
      /* EMBARCADOS */
      font-family: 'Pluto Sans' !important;
      font-style: normal  !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      /* Linha/Normal */
      /* color: #E0E0E0; */
    }
    .nav-item .active{
      border-bottom: 1px solid #E0E0E0 !important;
    }
    .nav-link:hover{
      /* EMBARCADOS */
      color: #E0E0E0 !important;
      border-bottom: 1px solid #E0E0E0 !important;
    }
    .nav-item h6{
      color: #ffffff;
      line-height: 56px !important;
    }
    #linha21{
      width: 20%;
      border-bottom: 3px solid #c3c3c3c3;
    }
    #linha81{
      width: 60%;
      border-bottom: 3px solid #c3c3c3c3 !important;
    }
    #linha {
      width: 80%;
      border-bottom: 3px solid ;
    }
    #linha90{
      width: 70%;
      border-bottom: 3px solid ;
    }
    #linha80{
      width: 60%;
      border-bottom: 3px solid ;
    }
    #linha10{
      width: 10%;
      border-bottom: 3px solid #c3c3c3c3 !important;
    }
    #linha20{
      width: 20%;
      border-bottom: 3px solid #c3c3c3c3 !important;
    }
    #linha51{
      width: 40%;
      border-bottom: 3px solid #c3c3c3c3 !important;
    }
    #linha50{
      width: 40%;
      border-bottom: 3px solid #c3c3c3c3;
    }
    #linha11{
      width: 10%;
      border-bottom: 3px solid #c3c3c3c3;
    }
    #linha91{
      width: 70%;
      border-bottom: 3px solid #c3c3c3c3 !important;
    }
    .nav{
      justify-content: center;
      /* 10 */
      font-family: 'Pluto Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
      /* identical to box height */
      letter-spacing: -0.02em;
      /* color: #FFFFFF; */
    }
    nav {
      position: sticky;
      top: 0;
    }
    .line-tabs .nav-link {
      padding: 0.8rem 1.2rem;
    }
  </style>
